<template>
    <router-link to="/my-events/index">
      <Button class="btn-return title-normal mt-1 mb-4">
        <img :src="require(`@/static/img_events/left-arrow.png`)" alt="logo" height="15" width="15" class="mr-1 ml-2" />
        <span>Regresar</span>
      </Button>
    </router-link>
  <a-row :gutter="25">
    <a-col :xxl="24" :lg="24" :md="24" :xs="24">
      <Suspense>
        <template #default>
          <sdCards headless>
            <a-row :gutter="25">
              <a-col :xxl="16" :lg="12" :md="12" :sm="24" :xs="24">
                <h3 class="title-bold-normal">{{ coreDataForm.eventData.title }}</h3>
              </a-col>
            </a-row>
            <a-row :grutter="25" v-if="!coreDataForm.showTableByRequest">
              <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" />
              </a-col>
            </a-row>
            <a-row :grutter="25" v-if="coreDataForm.showTableByRequest">
              <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="dataForm.tableLoading">
                <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" />
              </a-col>
              <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-else>
                <DataTable
                  :columns="participantsTableColumns"
                  :data="dataSource"
                  :totalRecords="dataForm.totalRecords"
                  v-on:getCurrentPaginator="getDataFromPaginatorNumber"
                  :bordered="true"
                />
              </a-col>
            </a-row>
          </sdCards>
        </template>
        <template #fallback>
          <sdCards headless>
            <a-skeleton active />
          </sdCards>
        </template>
      </Suspense>
    </a-col>
  </a-row>
</template>
<script>
import { defineAsyncComponent, reactive, h, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getSubevents, getEvent } from '@/services/events';
import { getItem } from '@/utility/localStorageControl'
import { formatDate } from '@/utility/utility';

const DataTable = defineAsyncComponent(() => import('../../../components/DataTable/DataTable.vue'));

const SubeventComponent = {
  name: 'SubeventsOverview',
  components: {
    DataTable,
  },
  props: {},
  watch: {},
  async setup() {
    const iconActions = require('@/static/img_events/SEJ_Eventos_opciones-21-2.svg');
    const iconDeleteParticipant = require('@/static/img_events/Eventos_ver_participantes.svg');
    const roles = getItem("roles");
    const router = useRouter();
    roles[0].nombre = roles[0].nombre.normalize('NFD')
     .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2")
     .normalize();

    const generateObjectDataTable = subevents => {
      let data = [];
      data = subevents.map(element => {
        return {
          key: element.id,
          ...element,
        };
      });
      return data;
    };
    const idEvent = useRoute().params.id;
    const getSubeventsInfo = async (numerPage) =>{
      let subevents = [];
      await getSubevents(idEvent, numerPage).then((response) => {
        if (response.success) {
          subevents = generateObjectDataTable(response.data.data);
          dataForm.totalRecords = response.data.total;
        }
      })
      return subevents;
    }

    const getEventDetail = async () => {
      let event = {};
      await getEvent(idEvent).then((response) => {
        if (response.success) {
          event = response.event
        }
      })
      .catch((e)=>{
        console.log(e)
      })
      return event;
    }

    const dataForm = reactive({
      searchText: "",
      totalRecords: 0,
      tableLoading: false,
      reportLoading: false,
    });

    const coreDataForm = reactive({
      subeventsData: await getSubeventsInfo(1),
      eventData: await getEventDetail(),
      showTableByRequest: true,
      selectedParticipants: [],
    })

    const dataSource = computed(
      () =>
        coreDataForm.subeventsData.length &&
        coreDataForm.subeventsData.map(value => {
          const { id, title, event_start_date, event_end_date } = value;
          return {
            key: id,
            title: <span class="date-started">{title}</span>,
            event_start_date: <span class="date-finished">{formatDate(event_start_date)}</span>,
            event_end_date: <span>{formatDate(event_end_date)}</span>,
            actions: (
              <sdDropdown
                action="click"
                class="dropdown-participants  actions-column"
                overlay={(h(
                  'div',
                  {
                    class: 'dropdown-actions'
                  },
                  [
                    h(
                      'button',
                      {
                        onClick: () => handlerSendViewParticipants(id),
                        class: 'btn-dropdown'
                      },
                      [
                        h(
                          'img',
                          {
                            class: 'btn-dropdown_logo',
                            src: iconDeleteParticipant,
                          }
                        ),
                        `Ver participantes`
                      ]
                    )
                  ]
                ))
                }
              >
                <img class="btn-dropdown-actions" src={iconActions} alt="Acciones" />
              </sdDropdown>
            ),
          };
        }),
    );

    const handlerSendViewParticipants = (subeventId) => {
        router.push({name: 'subeventParticipants', params: { id: idEvent, subeventId }});
    }

    const handleChangeSearch = e => {
      dataForm.searchText = e.target.value;
    };

    const getDataFromPaginatorNumber = async number => {
      dataForm.tableLoading = true;
      coreDataForm.subeventsData = await getSubeventsInfo(number, dataForm.searchText);
      dataForm.tableLoading = false;
    };

    const sendViewForm = (idParticipant) => {
      router.push({name: 'answers', params: {id: idParticipant}})
    }

    const participantsTableColumns = [
      {
        title: 'Nombre del evento',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Fecha de inicio',
        dataIndex: 'event_start_date',
        key: 'event_start_date',
      },
      {
        title: 'Fecha de finalización',
        dataIndex: 'event_end_date',
        key: 'event_end_date',
      },
      {
        title: 'Acciones',
        dataIndex: 'actions',
        key: 'actions',
        align: "center"
      },
    ];

    return {
      roles,
      participantsTableColumns,
      dataForm,
      coreDataForm,
      dataSource,
      handleChangeSearch,
      getDataFromPaginatorNumber,
      sendViewForm,
    };
  },
};

export default SubeventComponent;
</script>
<style scoped>
.bt-option-card {
  width: 100%;
  color: #212529;
  background: none;
  border: none;
  padding-left: 15px !important;
  padding: 5px;
  font-size: 12px;
  margin-bottom: 0.5rem;
}
.bt-option-card:hover {
  cursor: pointer;
  background: #f1efef;
}
.list-data {
  margin-bottom: 0.5rem;
}
.box-event {
  border: 1px solid #bcbdbe;
  border-radius: 7px;
  padding: 0 !important;
  margin-left: 0.5rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
.btn-delete-various {
  background-color: #FFFFFF;
  border: 1px solid #E8394D;
  border-radius: 20px;
  color: #E8394D;
  margin-bottom: 4px;
  padding: 7px;
}
.btn-add-event {
  background: #e70e4c;
  color: #fff;
  border-radius: 20px;
  border: none;
  margin-bottom: 4px;
  padding: 7px;
}
.btn-add-event:hover {
  cursor: pointer;
}
.title-more-events {
  color: #3FA7E1;
  font-size: 14px;
  font-weight: bold;
}
.title-more-events:hover {
  cursor: pointer;
}
.type-btn:hover {
  cursor: pointer;
}
.input-field {
  border-radius: 30px;
}
.btn-dropdown-actions {
  width: 32px;
}
.btn-dropdown-actions:hover {
  cursor: pointer;
}
.dropdown-actions {
  display: flex;
  flex-direction: column;
}
.dropdown-actions .btn-dropdown {
  background-color: transparent;
  border: none;
  text-align: start;
  margin-bottom: 4px;
}
.dropdown-actions .btn-dropdown:hover {
  cursor: pointer;
}
.dropdown-actions .btn-dropdown .btn-dropdown_logo {
  width: 14px;
  margin-right: 8px;
  margin-left: 4px;
}
.title-modal {
  color: #7B868C;
  font-size: 14px;
}
.modal-buttons {
  width: 100%;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
}
.modal-buttons .btn-cancel {
  background-color: #FFFFFF;
  border: 1px solid #707070;
  border-radius: 20px;
  color: #404040;
  font-size: 12px;
  padding: .3rem;
  width: 90px;
  margin: 0 5px;
}
.modal-buttons .btn-cancel:hover{
  cursor: pointer;
}
.modal-buttons .btn-accept {
  background-color: #3FA7E1;
  border: none;
  border-radius: 20px;
  color: #FFFFFF;
  font-size: 12px;
  padding: .3rem;
  width: 90px;
  margin: 0 5px;
}
.modal-buttons .btn-accept:hover {
  cursor: pointer;
}

.actions-column {
  text-align: center;
  align-self: center;
}
.btn-return {
  background: none;
  border: none;
  color: #7b868c;
  cursor: pointer !important;
}
</style>
<style>
.dropdown-participants {
  border: 1px solid #CCCCCC;
  box-shadow: 0px 3px 6px #00000029 !important;
  padding: 8px !important;
}
</style>
